export default class {
    socket = null;
    options = null;
    authentication = null;
    events = [];

    constructor(options = {}) {
        this.options = options;
    }

    connect() {
        if (typeof io == 'undefined') {
            return;
        }

        let sApp = '?app=portal_1';

        if (this.options.location === 'ao') {
            sApp = '?app=ao';
        }

        this.socket = io(this.options.chatConnectUrl + sApp);

        if (this.options.triggerAuth) {
            this.authenticate();
        }

        setTimeout(() => {
            if (!this.socket.connected) {
                throw new Error('Timeout while connecting');
            }
        }, 2500);

        this.socket.on('connect', () => {
            this.authenticate();
            this.emit('connected');
        });

        this.socket.on('authentication', response => {
            if (response.status === 'success') {
                this.authentication = response.data;
                this.emit('authenticated');
            } else {
                throw new Error('Authentication failure');
            }
        });

        this.socket.on('history', response => {
            // TODO: use this response when the socket is stable
            this.emit('history', response);
        });

        this.socket.on('disconnect', response => {
            this.emit('disconnect', response);
        });

        this.socket.on('joinConversation', response => {
            this.emit('joinConversation', response);
        });

        this.socket.on('chat', response => {
            this.emit('chat', response);
        });

        this.socket.on('markRead', response => {
            this.emit('markRead', response);
        });

        this.socket.on('typingStatus', response => {
            this.emit('typingStatus', response);
        });

        this.socket.on('leaveConversation', response => {
            this.emit('leaveConversation', response);
        });

        this.socket.on('delivered', response => {
            this.emit('delivered', response);
        });

        this.socket.on('block', response => {
            this.emit('block', response);
        });

        this.socket.on('presence', response => {
            this.emit('presence', response);
        });

        this.initEvents();
    }

    initEvents() {
        window.onfocus = function () {
            document.title = document.title.replace('1 mesaj nou - ', '');
            if (typeof this.emit !== 'undefined') {
                this.emit('markRead', {});
            }
        };
    }

    authenticate() {
        const { token, agentId, location } = this.options;

        this.socket.emit('authentication', {
            token,
            agentId,
            location,
        });
    }

    history(conversationId) {
        this.socket.emit('history', { conversationId });
    }

    disconnect() {
        this.socket.close();

        setTimeout(() => {
            this.socket.connect();
        }, 1000);
    }

    join(data) {
        if (
            (data.conversationId === undefined || data.conversationId == '' || data.conversationId == null) &&
            (data.offerId === undefined || data.offerId == '' || data.offerId == null)
        ) {
            return;
        }

        this.socket.emit('joinConversation', data);
    }

    leave(conversationId) {
        this.socket.emit('leaveConversation', { conversationId });
    }

    send(data) {
        this.socket.emit('chat', data);
    }

    externalEmit(emitType, data) {
        this.socket.emit(emitType, data);
    }

    on(type, callback) {
        if (!(type in this.events)) {
            this.events[type] = [];
        }

        this.events[type].push(callback);
    }

    off(type, callback) {
        this.events[type] = this.events[type].filter(next => next !== callback);
    }

    emit(type, data) {
        if (!(type in this.events)) {
            this.events[type] = [];
        }

        for (let callback of this.events[type]) {
            callback(data);
        }
    }
}
